














































import Vue from 'vue';
import mixinsVue from './mixins.vue';
export default Vue.extend({
  'name': 'ConfirmationPopup',
  'mixins': [mixinsVue],
  'props': {
    'confirmationMessage': {
      'type': String
    },
    'confirmationTitle': {
      'type': String
    },
    'confirmButton': {
      'type': String,
      'default': 'Confirm'
    },
    'discardButton': {
      'type': String,
      'default': 'Discard'
    },
    'showConfirmButton': {
      'type': Boolean,
      'default': true
    },
    'showDiscardButton': {
      'type': Boolean,
      'default': true
    }
  },
  'methods': {
    confirmed () {
      this.$emit('confirmed');
    },
    discarded () {
      this.$emit('discarded');
    }
  }
});
