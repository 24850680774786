





















































import {
  initPagination,
  Pagination,
  updatePagination
} from '@/types/Pagination';
import {
  initTableSortAndFilter,
  updateTableSort,
  TableSortAndFilter
} from '@/types/TableSortAndFilter';
import {
  AdminListResponse,
  AdminResponse,
  AdminListDetailResponse
} from '@/types/api/AdminList';
import SortableTable from '@/components/SortableTable.vue';
import ModalAddNewAdmin from '@/components/modals/AddNewAdmin.vue';
import ModalConfirmation from '@/components/modals/Confirmation.vue';
import {
  SortableTableDataConfig,
  SortableTableHeader
} from '@/types/SortableTableFormat';
import {
  cleanupFilterRequest,
  createFilterRequest
} from '@/types/api/FilterRequest';
import debounce from 'debounce';
import Vue from 'vue';
import { AxiosError } from 'axios';
import { ApiResponse } from '@/types/api/Response';
const deleteUserConfirmationTitle = 'Delete Confirmation';
const deleteUserConfirmationMessage = '<p>Are you sure you want to delete this user?</p><p>This action cannot be undone!</p>';
export default Vue.extend({
  'name': 'AdminList',
  'components': {
    SortableTable,
    ModalAddNewAdmin,
    ModalConfirmation
  },
  'watch': {
    'tableFilter.search' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    },
    'pagination.page' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    }
  },
  data () {
    const pagination = initPagination();
    const tableFilter = initTableSortAndFilter();
    return {
      pagination,
      tableFilter,
      'tableHeader': [
        {
          'key': 'role',
          'display': 'Type',
          'width': 250,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'customId',
          'display': 'ID No.',
          'width': 120,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'name',
          'display': 'Name',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'email',
          'display': 'Email',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'phone',
          'display': 'Phone No.',
          'width': 180,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'createdAt',
          'display': 'Created Date',
          'width': 180,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'createdBy.name',
          'display': 'Created By',
          'width': 220,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'isActive',
          'display': 'Status',
          'width': 160,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': '',
          'display': '',
          'width': 150,
          'cssClass': '',
          'isSortable': false,
          'sortOrder': 0,
          'isEmpty': true
        }
      ] as Array<SortableTableHeader>,
      'tableDataConfigs': [
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center font-weight-bold',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'isDate': true,
          'dateTimeFormat': 'DD MMM YYYY'
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center text-uppercase',
          'hasDisplay': true,
          'classFn': (val) => {
            return val && val !== 'INACTIVE'
              ? ''
              : 'text-danger font-weight-bold';
          }
        },
        {
          'cssClass': 'text-nowrap',
          'hasDisplay': false,
          'buttons': [
            {
              'cssClass': 'btn-iris-table btn-iris-edit',
              'event': 'edit'
            },
            {
              'cssClass': 'btn-iris-table btn-iris-delete',
              'event': 'delete'
            }
          ]
        }
      ] as Array<SortableTableDataConfig>,
      'tableData': [] as Array<AdminListDetailResponse>,
      'form': {
        'id': 0,
        'customId': '',
        'name': '',
        'email': '',
        'phone': '',
        'role': 0,
        'isActive': 1,
        'isSubmitting': false
      },
      'debounceGetList': null as unknown,
      'deleteUser': {
        'confirmationTitle': deleteUserConfirmationTitle,
        'confirmationMessage': deleteUserConfirmationMessage,
        'showConfirmButton': true,
        'discardButtonText': 'Discard',
        'userId': -1
      }
    };
  },
  mounted () {
    this.debounceGetList = debounce(this.getList, 500);

    this.getList();
  },
  'methods': {
    getList () {
      let getRequest = createFilterRequest(this.pagination, this.tableFilter);
      getRequest = cleanupFilterRequest(getRequest);

      this.axios
        .get('admin/list', {
          'params': getRequest
        })
        .then(({ data }) => {
          const response = data as AdminListResponse;
          this.tableData = response.result.items;

          const paginationUpdate = response.result as Pagination;
          updatePagination(this.pagination, paginationUpdate);
        });
    },
    addAdmin () {
      this.form.id = 0;
      this.form.customId = '';
      this.form.name = '';
      this.form.email = '';
      this.form.phone = '';
      this.form.role = 0;

      (this.$refs.modalAddNewAdmin as any).create();
    },
    editAdmin (rowIndex: number) {
      this.axios
        .get(`admin/${this.tableData[rowIndex].id}`)
        .then(({ data }) => {
          const response = data as AdminResponse;

          this.form.id = response.result.id;
          this.form.customId = response.result.customId;
          this.form.name = response.result.name;
          this.form.email = response.result.email;
          this.form.phone = response.result.phone;
          this.form.role = response.result.role;

          (this.$refs.modalAddNewAdmin as any).update();
        });
    },
    deleteAdmin (rowIndex: number) {
      this.deleteUser.userId = this.tableData[rowIndex]
        ? this.tableData[rowIndex].id
        : 0;
      this.deleteUser.confirmationTitle = deleteUserConfirmationTitle;
      this.deleteUser.confirmationMessage = deleteUserConfirmationMessage;
      this.deleteUser.showConfirmButton = true;
      this.deleteUser.discardButtonText = 'Discard';
      (this.$refs.deleteUserConfirmation as any).show();
    },
    confirmDeleteUser () {
      (this.$refs.deleteUserConfirmation as any).hide(() => {
        this.axios.delete(`admin/${this.deleteUser.userId}`).then(({ data }) => {
          this.getList();

          this.deleteUser.confirmationTitle = 'Success';
          this.deleteUser.confirmationMessage = data.message;
          this.deleteUser.showConfirmButton = false;
          this.deleteUser.discardButtonText = 'Dismiss';
        }).catch((err:AxiosError) => {
          this.deleteUser.confirmationTitle = 'Error';
          if (err && err.response) {
            const data = err.response.data as ApiResponse;
            this.deleteUser.confirmationMessage = data.message;
          }
          this.deleteUser.showConfirmButton = false;
          this.deleteUser.discardButtonText = 'Dismiss';
        }).finally(() => {
          (this.$refs.deleteUserConfirmation as any).show();
        });
      });
    },
    discardDeleteUser () {
      (this.$refs.deleteUserConfirmation as any).hide();
    },
    submitForm () {
      if (this.form.id === 0) {
        this.form.isSubmitting = true;
        this.axios
          .post('admin', {
            'customId': this.form.customId,
            'name': this.form.name,
            'email': this.form.email,
            'phone': this.form.phone,
            'role': this.form.role,
            'isActive': true
          })
          .then(() => {
            (this.$refs.modalAddNewAdmin as any).hide();
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.status === 422 &&
              err.response.data
            ) {
              const result = err.response.data.result;
              if (result) {
                (this.$refs.modalAddNewAdmin as any).setErrors(result);
              }
            }
          })
          .finally(() => {
            this.form.isSubmitting = false;
            this.getList();
          });
      } else {
        this.form.isSubmitting = true;
        this.axios
          .put('admin', {
            'customId': this.form.customId,
            'name': this.form.name,
            'email': this.form.email,
            'phone': this.form.phone,
            'role': this.form.role,
            'isActive': true,
            'adminId': this.form.id
          })
          .then(() => {
            (this.$refs.modalAddNewAdmin as any).hide();
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.status === 422 &&
              err.response.data
            ) {
              const result = err.response.data.result;
              if (result) {
                (this.$refs.modalAddNewAdmin as any).setErrors(result);
              }
            }
          })
          .finally(() => {
            this.form.isSubmitting = false;
            this.getList();
          });
      }
    },
    updateTableSort (tableSort: TableSortAndFilter) {
      updateTableSort(this.tableFilter, tableSort);
      this.getList();
    }
  }
});
