var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modalContent",staticClass:"modal fade modal-irispro",attrs:{"tabindex":"-1","role":"dialog","data-backdrop":"static","aria-hidden":"true","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-dialog",staticStyle:{"max-width":"720px"},attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"}),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.hide}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.isAdd ? "Add New Admin" : "View Admin")+" ")]),_c('div',{staticClass:"modal-form"},[_c('div',{staticStyle:{"width":"350px","margin":"0 auto"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"fm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"mb-3"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.role),expression:"value.role"}],staticClass:"form-control",attrs:{"placeholder":"Account Type"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.value, "role", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":0}},[_vm._v("SuperAdmin")]),_c('option',{domProps:{"value":1}},[_vm._v("Admin")])])]),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"customid","name":"admin's id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.customId),expression:"value.customId"}],class:{
                        'form-control': true,
                        'is-invalid': errors.length > 0,
                      },attrs:{"type":"text","placeholder":"Admin ID."},domProps:{"value":(_vm.value.customId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "customId", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"name","name":"admin's full name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.name),expression:"value.name"}],class:{
                        'form-control': true,
                        'is-invalid': errors.length > 0,
                      },attrs:{"type":"text","placeholder":"Admin's Full Name"},domProps:{"value":(_vm.value.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"email","name":"admin's email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.email),expression:"value.email"}],class:{
                        'form-control': true,
                        'is-invalid': errors.length > 0,
                      },attrs:{"type":"text","placeholder":"Admin's Email"},domProps:{"value":(_vm.value.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"vid":"phone","name":"admin's phone no.","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.phone),expression:"value.phone"}],class:{
                        'form-control': true,
                        'is-invalid': errors.length > 0,
                      },attrs:{"type":"text","placeholder":"Admin's Phone No."},domProps:{"value":(_vm.value.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "phone", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{ref:"hiddenSubmit",staticClass:"invisible",attrs:{"type":"submit"}})])]}}])})],1)])]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"modal-button d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-iris",attrs:{"disabled":_vm.value.isSubmitting},on:{"click":function($event){return _vm.$refs.hiddenSubmit.click()}}},[_vm._v(" "+_vm._s(_vm.isAdd ? "Create Admin" : "Update Admin")+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }